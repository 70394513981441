var CallPage = function (ajaxObj) {


    if(!ajaxObj) {console.warn('No object is passed to CallPage!'); return;}
    ajaxObj.headers= { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')};
    ajaxObj.dataType = 'json';
    ajaxObj.method = 'POST';

    var msg;

    $.ajax(ajaxObj)
        .done(function (data) {
            if (ajaxObj.dataComplete) ajaxObj.dataComplete.call(this, data);
        })
        .always(function () {
            if (ajaxObj.always) ajaxObj.always.call(this, this)
        }).fail(function (jqXHR) {
        switch (jqXHR.status) {
            case 500: msg = 'Server ERROR: ' + jqXHR.statusText ;break;
            case 404: msg = 'Status: ' + jqXHR.status + ' Rsponce: ' + jqXHR.statusText; break;
            case 422:
                if (ajaxObj.dataError) ajaxObj.dataError.call(this, JSON.parse(jqXHR.responseText));
                msg = 'Status: ' + jqXHR.status + ' Rsponce: ' + jqXHR.statusText;
                break;
        }

        if (ajaxObj.dataComplete) ajaxObj.dataComplete.call(this, {status:-1});

        if (ajaxObj.debug) console.warn(msg + ' '+ jqXHR.responseText)
    })

    return false
}

